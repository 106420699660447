
import {
  Vue, Component, Prop, Watch,
} from 'vue-property-decorator';
import ActionButton from '@/components/Button/Button.vue';
import { Filter } from '@/types/ListTypes';

interface RangeFilter {
  start?: string|null|number,
  end?: string|null|number,
  'include_null': boolean|null,
}

@Component({
  name: 'NumberFilter',
  components: {
    ActionButton,
  },
})
export default class NumberFilter extends Vue {
  @Prop({ required: true })
  private readonly filter!: Filter;

  @Prop()
  private readonly prefix?: string;

  private inputValue: RangeFilter = {
    include_null: null,
    start: this.filter.metadata?.min,
    end: this.filter.metadata?.max,
  }

  get min(): number {
    let minValue = this.filter.metadata?.min ?? 0;

    if (typeof minValue === 'string') {
      minValue = parseFloat(minValue);
    }

    return minValue as number;
  }

  get max(): number {
    let maxValue = this.filter.metadata?.max ?? 0;

    if (typeof maxValue === 'string') {
      maxValue = parseFloat(maxValue);
    }

    return maxValue as number;
  }

  @Watch('filter.data', { immediate: true })
  private syncFilterDataWithInputData(): void {
    if (this.filter.data === null) {
      Object.keys(this.inputValue).forEach((key: string) => { this.inputValue[(key as keyof RangeFilter)] = null; });
    } else {
      this.inputValue = { ...(this.filter.data as RangeFilter) };
    }

    if (this.inputValue.end === null && this.inputValue.start === null) {
      this.inputValue.end = this.min;
      this.inputValue.start = this.max;
    }
  }

  private applyFilter(): void {
    this.filter.data = this.inputValue;
    this.filter.applied = true;
    this.$emit('fetchData');
  }
}
